import { useRouter } from 'next/router';

import RouterConfig from '@/constants/route.config';
import type { TypeRouterConfig } from '@/types/general';

interface ReturnType {
  layout: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  route?: TypeRouterConfig | { [key: string]: any };
}

{
  /* How to Use */
}
{
  /*
  const { layout, route } = useLayout();
*/
}

const useLayout = (): ReturnType => {
  const router = useRouter();
  
  const route = RouterConfig?.find((each) => each?.path === router?.pathname);
  return {
    layout: route ? route?.layout : 'fullscreen',
    route: route,
  };
};

export default useLayout;
