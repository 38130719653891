import dynamic from "next/dynamic";

import Footer from "@/components/Footer";
import useHydrated from "@/hooks/useHydrated";
import useLayout from "@/hooks/useLayout.next";
import { useMediaQuery } from "react-responsive";
import BodyHelmet from "@/components/Helmets/Body.js";

const Fullscreen = dynamic(() => import("@/layouts/Fullscreen"));
const Main = dynamic(() => import("@/layouts/Main"));
const Dashboard = dynamic(() => import("@/layouts/Dashboard"));

export default function SiteLayout({children}: React.PropsWithChildren) {
  const isHydrated = useHydrated();
  
  const isLg = useMediaQuery({query: "(min-width: 1024px)"});
  const {layout, route} = useLayout();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MAP_LAYOUT: Record<string, any> = {
    fullscreen: Fullscreen,
    default: Main,
    dashboard: Dashboard,
  };
  const Component = MAP_LAYOUT[layout ?? "fullscreen"];
  const showFooter = isHydrated ? isLg ? route?.footer : route?.footer || route?.footerMobileOnly : false;
  return (
    <>
      <Component layout={layout}>
        {children}
      </Component>
      {showFooter && <Footer />}
      {isHydrated && <BodyHelmet />}
    </>
  );
}

export const getLayout = (page: React.ReactElement) => (
  <SiteLayout>{page}</SiteLayout>
);
