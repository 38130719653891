export enum Layout {
  DEFAULT = 'default',
  FULLSCREEN = 'fullscreen',
  DASHBOARD = 'dashboard',
}
export enum DisplayOn {
  MAIN_MENU = 'mainmenu',
  SIDE_MENU = 'sidemenu',
}

type HeroIcon = (props: React.ComponentProps<'svg'>) => JSX.Element;

export interface ApiResponse {
  statusCode: number;
  message: string;
  data: null;
  service: string;
  timestamp: Date;
}

export interface TypeRouterConfig {
  id: string;
  title: string;
  path: string;
  layout: Layout;
  children?: Array<TypeRouterConfig>;
  icon?: string | HeroIcon;
  displayOn: DisplayOn[];
  image?: string;
  footer?: boolean;
  footerMobileOnly?: boolean;
  btnRight?: string;
}
