import useBackgroundStore from "@/zus/background";
import { Helmet } from "react-helmet";

const styleToString = (style) => {
    return Object.keys(style).reduce((acc, key) => (
      acc + key.split(/(?=[A-Z])/).join('-').toLowerCase() + ':' + style[key] + ';'
    ), '');
  };

function BodyHelmet() {
  const {style} = useBackgroundStore();
  const theStyle = styleToString(style);
  return (
    <>
      <Helmet>
        <body style={theStyle} />
      </Helmet>
    </>
  );
}
export default BodyHelmet;
