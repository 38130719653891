import Home from '@/components/PageGroups/dashboard/Home';
import { getLayout } from '@/layouts';

const Homepage = () => {
    return <Home />;
};

Homepage.getLayout = getLayout;

export default Homepage;
