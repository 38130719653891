
import { DisplayOn, Layout, TypeRouterConfig } from '@/types/general';

const ROUTE_CONFIG: TypeRouterConfig[] = [
  {
    id: 'landing',
    title: 'Home',
    path: '/',
    layout: Layout.DEFAULT,
    displayOn: [DisplayOn.MAIN_MENU],
    footer: true,
    footerMobileOnly: true,
    btnRight: "signin",
    image: "/static/images/brand/daikin-logo.svg"
  },
  {
    id: 'privacy',
    title: 'Privacy',
    path: '/auth/privacy',
    footer: true,
    footerMobileOnly: true,
    layout: Layout.DEFAULT,
    displayOn: [],
  },
  {
    id: 'signin',
    title: 'Sign In',
    path: '/auth/signin',
    footer: true,
    footerMobileOnly: true,
    btnRight: "signup",
    layout: Layout.DEFAULT,
    displayOn: [],
  },
  {
    id: 'signup',
    title: 'Sign Up',
    path: '/auth/signup',
    footer: true,
    footerMobileOnly: true,
    btnRight: "signin",
    layout: Layout.DEFAULT,
    displayOn: [],
  },
  {
    id: 'home',
    title: 'Home',
    path: '/dashboard/home',
    layout: Layout.DASHBOARD,
    displayOn: [],
    footer: true,
    image: "/static/images/brand/daikin-logo.svg"
  },
  {
    id: 'device',
    title: 'My Device',
    path: '/dashboard/devices',
    layout: Layout.DASHBOARD,
    footer: true,
    displayOn: [DisplayOn.MAIN_MENU],
  },
  {
    id: 'orders',
    title: 'My Order',
    path: '/dashboard/orders',
    layout: Layout.DASHBOARD,
    footer: true,
    displayOn: [DisplayOn.MAIN_MENU],
  },
  {
    id: 'profile',
    title: 'My Profile',
    path: '/dashboard/profile',
    footer: true,
    layout: Layout.DASHBOARD,
    displayOn: [],
  },
  {
    id: 'orders',
    title: 'My Order Detail',
    path: '/dashboard/orders/[id]',
    layout: Layout.DASHBOARD,
    footer: true,
    displayOn: [],
  },
];

export default ROUTE_CONFIG;
