import useBackground from "@/hooks/useBackground";
import useHydrated from "@/hooks/useHydrated";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";

const Home = () => {
  const isHydrated = useHydrated();
  useBackground({
    defaultValue: {
      background: "linear-gradient(229.85deg, #6AC5EF 0%, #FFFFFF 72.88%)"
    },
    lg: {
      background:"linear-gradient(229.85deg, #6AC5EF 0%, #FFFFFF 82.88%)"
    },
  });
  const isMd = useMediaQuery({query: "(min-width: 768px)"});
  const playstoreImg = isMd ? "/static/images/playstore.png" : "/static/images/playstore-mobile.png"
  const appstoreImg = isMd ? "/static/images/appstore.png" : "/static/images/appstore-mobile.png"
  
  return (
    <div
      className={clsx(
        "mb-8  relative  flex justify-center items-center h-[80vh]",
        "-mt-[10rem] min-h-[900px] pt-[8rem]",
        "lg:-mt-[8rem] lg:min-h-[600px] lg:pt-[5rem]",
      )}
    >
      <div
        className={clsx(
          "flex w-full relative z-10", // style gk berhubungan sama responsive
          "items-center flex-col-reverse px-[1rem]", // mobile
          "lg:items-start lg:flex-row lg:px-[5rem]", // desktop
        )}
      >
        <div className="flex-1 lg:mr-5 mt-5 lg:mt-0 text-center lg:text-left xl:text-left 2xl:text-left">
          <h3 className="text-primary text-3xl lg:text-5xl font-bold lg:mb-1">
            Faster and Easier
          </h3>
          <h4 className="text-secondary text-[1rem] lg:text-4xl mb-2 lg:mb-4 font-medium">
            Maintenance With Daikin Mobile Apps
          </h4>
          <p className="text-xs lg:text-md">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis.
          </p>
          {isHydrated && <div className="flex flex-col mt-6 md:mt-4 items-center lg:items-start justify-start lg:-ml-3">
            <img
              src={playstoreImg}
              className="w-[7.5rem] md:w-40 cursor-pointer mb-4 md:mb-0"
              alt="playstore"
            />
            <img
              src={appstoreImg}
              className="w-[7.5rem] md:w-40 cursor-pointer mb-4 md:mb-0"
              alt="appstore"
            />
          </div>}
        </div>
        <img
          className="w-[300px] lg:w-[400px]"
          src="/static/vectors/daikin-mobile-preview2.png"
          alt="daikinAppPreview"
        />
      </div>
      <div
        className={clsx(
          "absolute left-0 right-0 bottom-0 w-full z-1 bg-contain bg-no-repeat",
          "bg-[url('/static/vectors/half-box2.svg')] top-[8rem]",
          "lg:bg-[url('/static/vectors/half-box.svg')] lg:top-[2rem]",
        )}
      ></div>
    </div>
  );
};

export default Home;
