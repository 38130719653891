import {create} from "zustand";
import CSS from "csstype";

interface backgroundState {
  style: CSS.Properties;
  setStyle: (val: CSS.Properties) => void;
  reset: () => void;
}

const useBackgroundStore = create<backgroundState>((set) => ({
  style: {
    background: "white",
    backgroundSize: "contain",
  },
  setStyle: (val: CSS.Properties) => {
    set({style: val});
  },
  reset: () =>
    set({
      style: {
        background: "white",
        backgroundSize: "contain",
      },
    }),
}));

export default useBackgroundStore;
