const FooterDefault = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="relative px-5 md:px-20 text-[#565656] text-xs -mt-7 mb-5 z-0">
      © {year} PT Daikin Airconditioning Indonesia. All rights reserved
    </footer>
  );
};

export default FooterDefault;
