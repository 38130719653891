import useBackgroundStore from "@/zus/background";
import {useEffect} from "react";
import {useMediaQuery} from "react-responsive";
import CSS from "csstype";

const useBackground = (value: {
  defaultValue: CSS.Properties;
  md?: CSS.Properties;
  lg?: CSS.Properties;
}) => {
  const {style,setStyle, reset} = useBackgroundStore();
  const isMd = useMediaQuery({query: "(min-width: 768px)"});
  const isLg = useMediaQuery({query: "(min-width: 1024px)"});
  useEffect(() => {
    if (isLg) {
      setStyle(value?.lg || value?.md || value?.defaultValue || style);
    } else if (isMd) {
      setStyle(value?.md || value?.defaultValue);
    } else {
      setStyle(value?.defaultValue);
    }
    return () => {
      reset()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMd, isLg]);

  return style;
};

export default useBackground;
